import React, {useEffect, useMemo, useReducer, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Button from '../components/Button'
import LoadingOverlay from '../components/LoadingOverlay'
import { MapComponent } from '../components/map/MapComponent'
import { Menu } from '../components/Menu'
import EventCreateSlideOver from '../containers/slide-overs/EventCreateSlideOver'
import EventInfoSlideOver from '../features/events/components/EventsInfoSlideOver'
import { fetchEvents } from '../features/events/thunks'
// import VolunteerInfoSlideOver from '../features/volunteers/components/VolunteerInfoSlideOver'
// import { fetchVolunteers } from '../features/volunteers/thunks'
import { EventType, EventTypeDetails } from '../api/events.facade'
import {Checkmark} from "../components/icons";

// function volunteersToMarkers(volunteers) {
//   return volunteers.map((volunteer) => ({
//     id: `volunteer/${volunteer.id}`,
//     type: 'rescuer', // TODO: replace with volunteer.role when implemented
//     position: [volunteer.longitude, volunteer.latitude],
//     selected: false
//   }))
// }

function eventsToMarkers(events) {
  return events.map((event) => ({
    id: `event/${event.id}`,
    type: 'event',
    position: [event.address.latitude, event.address.longitude],
    selected: false
  }))
}

export function MapPage() {
  const dispatch = useDispatch()
  const [selectedMarker, setSelectedMarker] = useState(null)
  const [showEndedEvents, setShowEndedEvents] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState(null)
  const [statuses, setStatuses] = useState('IN_PROGRESS,CREATED')
  const [isEventCreateSlideOverOpen, setIsEventCreateSlideOverOpen] =
    useState(false)
  const [markers, markerDispatch] = useReducer((state, action) => {
    if (action.type === 'MARKERS/add') {
      return [...state, action.payload]
    }

    if (action.type === 'MARKERS/remove') {
      return state.filter((marker) => marker.id !== action.payload.id)
    }

    if (action.type === 'MARKERS/set') {
      return action.payload
    }

    if (action.type === 'MARKERS/select') {
      return state.map((marker) => {
        if (marker.id === action.payload.id) {
          return { ...marker, selected: true }
        }
        return { ...marker, selected: false }
      })
    }

    if (action.type === 'MARKERS/unselect') {
      return state.map((marker) => {
        if (marker.id === action.payload.id) {
          return { ...marker, selected: false }
        }
        return marker
      })
    }
  }, [])

  // const { data: volunteers, loading: volunteersLoading } = useSelector(
  //   (state) => state.volunteers
  // )

  const handleFilterEndedEvents = (e) => {
    const checked = e.target.checked
    setShowEndedEvents(checked)
    setStatuses(checked ? 'FINISHED,IN_PROGRESS,CREATED' : 'IN_PROGRESS,CREATED')
  }

  const { data: events, loading: eventsLoading } = useSelector(
    (state) => state.events
  )

  const filteredEvents = useMemo(() => {
    return events.filter(event => {
      return selectedFilter !== null ? event.eventType === selectedFilter : true
    })
  }, [events, selectedFilter])

  const eventTypeOptions = Object.values(EventType).reduce(
    (options, eventType) => {
      const details = EventTypeDetails[eventType]
      if (!details) return options

      return options.concat({
        value: eventType,
        label: EventTypeDetails[eventType].text
      })
    },
    []
  )

  // function onMapClick({ lat, lng }) {
  //   const marker = {
  //     id: 'event/new',
  //     type: 'event',
  //     position: [lat, lng],
  //     selected: true
  //   }
  //   markerDispatch({ type: 'MARKERS/remove', payload: { id: 'event/new' } })
  //   markerDispatch({ type: 'MARKERS/add', payload: marker })
  //   setSelectedMarker(marker)
  // }

  function onMarkerClick(marker) {
    marker.selected = true
    markerDispatch({ type: 'MARKERS/select', payload: marker })
    setSelectedMarker(marker)
  }

  function isOpenType(type) {
    if (selectedMarker === null) return false
    if (type === 'new' && selectedMarker.id === 'event/new') return true
    if (selectedMarker.id !== 'event/new') {
      return selectedMarker.id.split('/')[0] === type
    }
    return false
  }

  const handleFilterChange = (value) => {
    setSelectedFilter(value)
  }

  useEffect(() => {
    const pageSettings = {
      page: 1,
      size: 300,
      sortBy: '',
      sortOrder: '',
      filter: '',
      status: statuses
    }
    // dispatch(fetchVolunteers(pageSettings))
    dispatch(fetchEvents(pageSettings))
  }, [dispatch, statuses])

  useEffect(() => {
    const m = [...eventsToMarkers(filteredEvents)]
    markerDispatch({ type: 'MARKERS/set', payload: m })
  }, [filteredEvents, events])

  return (
    <>
      <div className="mt-5 flex justify-between items-center">
        <h1 className="text-body-900 text-lg font-bold">Мапа</h1>
        <Button
          icon="Add"
          variant="secondary"
          onClick={() => setIsEventCreateSlideOverOpen(true)}
        >
          Додати подію
        </Button>
      </div>
      <div className="flex gap-6 items-center">
        <div className="flex items-center gap-2">
          <span>Фільтр подій:</span>
          <Menu
              selected={selectedFilter}
              items={[{value: null, label: 'Всі'}, ...eventTypeOptions]}
              onSelect={handleFilterChange}
          />
        </div>

        <label className="flex items-center gap-2.5">
          <input
              type="checkbox"
              id="showEndedEvents"
              className="hidden opacity-0"
              onChange={handleFilterEndedEvents}
          />
          <div
              className={`flex justify-center items-center border border-primary-500 rounded-md h-4 w-4 ${
                  showEndedEvents ? 'bg-primary-500' : ''
              }`}
          >
            {showEndedEvents && (
                <Checkmark className="w-4 h-4 text-white"/>
            )}
          </div>
          Показувати завершені
        </label>
      </div>

      <div className="relative z-0 pt-4 flex-grow">
        <MapComponent
            markers={markers}
            // onClick={onMapClick}
            onMarkerClick={onMarkerClick}
        />
      </div>

      <EventCreateSlideOver
          open={isEventCreateSlideOverOpen}
          setOpen={setIsEventCreateSlideOverOpen}
      />

      {/*<VolunteerInfoSlideOver*/}
      {/*  volunteer={volunteers.find(*/}
      {/*    (volunteer) => `volunteer/${volunteer.id}` === selectedMarker?.id*/}
      {/*  )}*/}
      {/*  isOpen={isOpenType('volunteer')}*/}
      {/*  toggle={() => {*/}
      {/*    markerDispatch({ type: 'MARKERS/unselect', payload: selectedMarker })*/}
      {/*    setSelectedMarker(null)*/}
      {/*  }}*/}
      {/*/>*/}

      <EventInfoSlideOver
        event={events.find(
          (event) => `event/${event.id}` === selectedMarker?.id
        )}
        isOpen={isOpenType('event')}
        toggle={() => {
          markerDispatch({ type: 'MARKERS/unselect', payload: selectedMarker })
          setSelectedMarker(null)
        }}
      />

      {eventsLoading && <LoadingOverlay />}
    </>
  )
}
