import { createAsyncThunk } from '@reduxjs/toolkit'

import * as facade from '../../api/analytics.facade'
import { ANALYTICS, VOLUNTEERS, EVENTS, REQUESTS } from './constants'
import { FETCH_LIST, FETCH, POST, PUT, DELETE } from '../../redux/actions'

export const fetchAnalyticsVolunteers = createAsyncThunk(
  `${ANALYTICS}/${VOLUNTEERS}`,
  async (pageable) => {
    return await facade.getAnalyticsVolunteers(pageable)
  }
)

export const fetchAnalyticsVolunteer = createAsyncThunk(
  `${ANALYTICS}/${VOLUNTEERS}/${FETCH}/${REQUESTS}`,
  async (id) => {
    return await facade.getAnalyticsVolunteer(id)
  }
)

export const fetchAnalyticsEvents = createAsyncThunk(
  `${ANALYTICS}/${EVENTS}`,
  async (pageable) => {
    return await facade.getAnalyticsEvents(pageable)
  }
)
