import * as React from 'react'
const SvgEvent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
  >
    <path d="m8.99 8.687-.004.002zm9.086-.17a1 1 0 0 0-.21-.158.92.92 0 0 0-1.278.372 5.2 5.2 0 0 1-1.276 1.608q.122-.678.123-1.366A7.8 7.8 0 0 0 14.36 5.01a7.9 7.9 0 0 0-2.941-2.891.92.92 0 0 0-1.22.299.9.9 0 0 0-.142.43 6.3 6.3 0 0 1-.665 2.463 6.4 6.4 0 0 1-1.59 2.005l-.21.17a7.7 7.7 0 0 0-1.815 1.684 8.1 8.1 0 0 0-1.602 3.38 8.05 8.05 0 0 0 .094 3.734c.324 1.22.93 2.35 1.772 3.297a8.2 8.2 0 0 0 3.073 2.163.92.92 0 0 0 1.15-.412.9.9 0 0 0 .061-.707 6.3 6.3 0 0 1-.173-2.333 8.2 8.2 0 0 0 3.936 3.63.92.92 0 0 0 .653.035 8.2 8.2 0 0 0 3.486-2.197 8.1 8.1 0 0 0 1.964-3.601 8.06 8.06 0 0 0-.055-4.093 8.1 8.1 0 0 0-2.06-3.547M14.512 20.12a6.35 6.35 0 0 1-2.06-1.646 6.3 6.3 0 0 1-1.205-2.334 8 8 0 0 1-.164-.872.914.914 0 0 0-1.36-.66.9.9 0 0 0-.335.332 8.04 8.04 0 0 0-1.1 4.266 6.3 6.3 0 0 1-1.638-1.88 6.28 6.28 0 0 1-.581-4.834c.23-.804.62-1.555 1.146-2.208.399-.514.88-.96 1.425-1.319a1 1 0 0 0 .069-.05s.27-.221.28-.226A8.1 8.1 0 0 0 11.634 4.5a6.07 6.07 0 0 1 1.886 3.443 6.04 6.04 0 0 1-.622 3.87.9.9 0 0 0 .108.998.91.91 0 0 0 .973.283 7.17 7.17 0 0 0 3.525-2.43 6.272 6.272 0 0 1 .806 5.443 6.3 6.3 0 0 1-1.437 2.436 6.4 6.4 0 0 1-2.362 1.576" />
  </svg>
)
export default SvgEvent
