import * as React from 'react'
const SvgDelete = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
  >
    <path
      d="M15.818 5.06h3.636a.91.91 0 0 1 0 1.819H4.909a.91.91 0 0 1 0-1.818h3.636V3.242a.91.91 0 0 1 .91-.909h5.454a.91.91 0 0 1 .909.91zm-5.455-.909v.91H14v-.91z"
      clipRule="evenodd"
    />
    <path d="M17.902 8.054a.91.91 0 0 1 1.552.643V18.87a3.56 3.56 0 0 1-3.645 3.462H8.554A3.56 3.56 0 0 1 4.91 18.87V8.697a.909.909 0 1 1 1.818 0V18.87a1.745 1.745 0 0 0 1.827 1.644h7.255a1.745 1.745 0 0 0 1.827-1.644V8.697c0-.241.095-.473.266-.643" />
    <path d="M11.272 16.878v-6.363a.91.91 0 0 0-1.818 0v6.363a.91.91 0 0 0 1.818 0M14.909 10.515v6.363a.909.909 0 1 1-1.819 0v-6.363a.91.91 0 0 1 1.819 0" />
  </svg>
)
export default SvgDelete
