import * as React from 'react'
const Svg = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M11.25 11.25C11.25 10.8358 11.5858 10.5 12 10.5C12.4142 10.5 12.75 10.8358 12.75 11.25V13.25H14.75C15.1642 13.25 15.5 13.5858 15.5 14C15.5 14.4142 15.1642 14.75 14.75 14.75H12.75V16.75C12.75 17.1642 12.4142 17.5 12 17.5C11.5858 17.5 11.25 17.1642 11.25 16.75V14.75H9.25C8.83579 14.75 8.5 14.4142 8.5 14C8.5 13.5858 8.83579 13.25 9.25 13.25H11.25V11.25Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 5.5V7H4C2.89543 7 2 7.89543 2 9V19C2 20.1046 2.89543 21 4 21H20C21.1046 21 22 20.1046 22 19V9C22 7.89543 21.1046 7 20 7H16V5.5C16 4.39543 15.1046 3.5 14 3.5H10C8.89543 3.5 8 4.39543 8 5.5ZM14 5H10C9.72386 5 9.5 5.22386 9.5 5.5V7H14.5V5.5C14.5 5.22386 14.2761 5 14 5ZM3.5 9C3.5 8.72386 3.72386 8.5 4 8.5H20C20.2761 8.5 20.5 8.72386 20.5 9V19C20.5 19.2761 20.2761 19.5 20 19.5H4C3.72386 19.5 3.5 19.2761 3.5 19V9Z"
    />
  </svg>
)

export default Svg
