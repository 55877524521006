import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit'

import volunteersReducer from '../features/volunteers/volunteersSlice'
import eventsReducer from '../features/events/eventsSlice'
import authReducer from '../features/auth/slice'
import mapEventsReducer from '../components/map/mapSlice'
import analyticsReducer from '../features/analytics/analyticsSlice'
import {loadAuthState, saveAuthState} from '../utils/localStorage'

const listenerMiddleware = createListenerMiddleware()

export const store = configureStore({
  reducer: {
    volunteers: volunteersReducer,
    events: eventsReducer,
    auth: authReducer,
    map: mapEventsReducer,
    analytics: analyticsReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
  preloadedState: {
    auth: loadAuthState()
  }
})

store.subscribe(() => {
  saveAuthState(store.getState()?.auth)
})
