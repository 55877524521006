import { createSlice } from '@reduxjs/toolkit'

import { extraReducers } from './reducers'

const initialState = {
  loading: false,
  username: null,
  token: null,
  role: null,
  error: null
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.username = null
      state.token = null
      state.role = null
    }
  },
  extraReducers
})

export const actions = authSlice.actions

export default authSlice.reducer
