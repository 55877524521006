import * as React from 'react'
const SvgHelmet = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
  >
    <path d="M20.55 15.125c-.204-3.424-2.166-6.481-5.061-7.883l.075-.67c.04-.397-.09-.796-.359-1.094A1.46 1.46 0 0 0 14.124 5H9.876c-.412 0-.806.174-1.081.479-.269.297-.4.697-.358 1.1l.074.664c-2.895 1.4-4.857 4.458-5.061 7.882-.84.29-1.45 1.08-1.45 2.018 0 1.181.961 2.143 2.143 2.143h15.714A2.145 2.145 0 0 0 22 17.143c0-.938-.61-1.728-1.45-2.018M8.681 8.77l.466 4.166a.714.714 0 0 0 1.42-.158l-.69-6.35 4.267-.007-.711 6.357a.713.713 0 0 0 .63.788.713.713 0 0 0 .79-.63l.465-4.167c2.13 1.234 3.573 3.586 3.794 6.23H4.887c.222-2.643 1.666-4.995 3.794-6.229m11.176 9.087H4.143a.715.715 0 0 1 0-1.428h15.714a.715.715 0 0 1 0 1.428" />
  </svg>
)
export default SvgHelmet
