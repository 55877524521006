import axiosInstance from '../utils/axios'

const ANALYTICS_VOLONTEER_PATH = 'analytics/volunteers'
const ANALYTICS_EVENTS_PATH = 'analytics/events'

/**
 * @enum {string}
 */

/**
 * @typedef {Object} Pageable
 * @property {number} page - The number of page (required)
 * @property {number} size - The max amount of items in page (required)
 * @property {string} [sortBy] - The field by which list content will be sorted (optional)
 * @property {string} [sortOrder] - The sorting order (optional)
 * @property {string} [filter] - The string by which items are filtered (optional)
 */

/**
 * @typedef {Object} AnalyticsEventsRequest
 * @property {number} id - The unique identifier for the event.
 * @property {EventType} eventType - The type of the event.
 * @property {string} zipCode
 * @property {string} street
 * @property {string} apartmentNumber
 * @property {string} city
 * @property {string} state
 * @property {number} latitude - The latitude coordinate of the event location.
 * @property {number} longitude - The longitude coordinate of the event location.
 * @property {string} description - The description of the event.
 * @property {number} requiredVolunteers - The number of required volunteers for the event.
 * @property {string} dateTime - The date and time of the event.
 */

/**
 * Returns a list of all volunteer.
 * @param {Pageable} pageable
 * @returns {Promise<Page<Analytics>>}
 */
export async function getAnalyticsVolunteers(pageable) {
  const { page = 1, size = 10, sortBy, sortOrder, filter } = pageable

  const response = await axiosInstance.get(`${ANALYTICS_VOLONTEER_PATH}`, {
    params: {
      page,
      ...(size > 0 && { size }),
      ...(sortBy && { sortBy }),
      ...(sortOrder && { sortOrder }),
      ...(filter && { filter })
    }
  })

  return response.data
}

/**
 * Returns an object representing a Volunteer with detailed information.
 * @param {number} id
 * @returns {Promise<Volunteer>}
 */
export async function getAnalyticsVolunteer(id) {
  const response = await axiosInstance.get(
    `${ANALYTICS_VOLONTEER_PATH}/${id}/requests`
  )
  return response.data
}

/**
 * Fetches events count.
 * @param {Pageable} pageable
 * @returns {Promise<Page<EventDTO>>} - A promise that resolves to an array of event objects.
 */
export const getAnalyticsEvents = async (pageable) => {
  const {
    page = 1,
    size = 10,
    sortBy,
    sortOrder,
    startDate,
    endDate
  } = pageable

  const response = await axiosInstance.get(`${ANALYTICS_EVENTS_PATH}`, {
    params: {
      page,
      ...(size > 0 && { size }),
      ...(sortBy && { sortBy }),
      ...(sortOrder && { sortOrder }),
      ...(startDate && { startDate }),
      ...(endDate && { endDate })
    }
  })

  return response.data
}
