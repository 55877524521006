import { Outlet } from 'react-router-dom'

import NavigationBar from '../containers/NavigationBar'
import Footer from '../containers/Footer'

export function Layout() {
  return (
    <div className="flex flex-col h-screen justify-between">
      <NavigationBar />

      <main className="relative w-full px-8 flex flex-col justify-between _h-[calc(100dvh-88px-64px-32px)] flex-grow">
        <Outlet />
      </main>

      <Footer />
    </div>
  )
}
