import React, { useCallback, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { fetchEvents } from '../features/events/thunks'
import { EventStatusDetails, EventTypeDetails } from '../api/events.facade'
import Pagination from '../components/Pagination'
import Status from '../components/Status'
import Icon from '../components/Icon'
import Button from '../components/Button'
import Select from '../components/Select'
import EventCreateSlideOver from '../containers/slide-overs/EventCreateSlideOver'
import EventDetailsSlideOver from '../containers/slide-overs/EventDetailsSlideOver'
import { Checkmark, Search } from '../components/icons'
import LoadingOverlay from "../components/LoadingOverlay";

const EventsPage = () => {
  const dispatch = useDispatch()
  const [showEndedEvents, setShowEndedEvents] = useState(false)
  const {
    data: events,
    pageDetails,
    loading,
    error
  } = useSelector((state) => state.events)

  const tableHeaderCellStyle =
    'px-6 py-3 text-left text-base font-medium text-body-900 tracking-wider cursor-pointer'

  const [pageable, setPageable] = useState({
    page: 1,
    size: 10,
    sortBy: 'status',
    sortOrder: 'asc',
    filter: null,
    status: 'IN_PROGRESS,CREATED'
  })

  useEffect(() => {
    dispatch(fetchEvents(pageable))
  }, [dispatch, pageable])

  const handlePageChange = useCallback((newPage) => {
    setPageable((prev) => ({ ...prev, page: newPage }))
  }, [])

  const handlePageSizeChange = useCallback((event) => {
    const newSize = Number(event.target.value)
    setPageable((prev) => ({ ...prev, size: newSize, page: 1 }))
  }, [])

  const pageSizeOptions = [
    { value: '10', label: '10' },
    { value: '20', label: '20' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
    { value: `${pageDetails.totalItems}`, label: 'Усі' }
  ]

  const handleSortChange = useCallback((field) => {
    setPageable((prev) => ({
      ...prev,
      sortBy: field,
      sortOrder: prev.sortOrder === 'asc' ? 'desc' : 'asc'
    }))
  }, [])

  const [isEventCreateSlideOverOpen, setIsEventCreateSlideOverOpen] =
    useState(false)
  const [isEventDetailsSlideOverOpen, setIsEventDetailsSlideOverOpen] =
    useState(false)
  const [selectedEvent, setSelectedEvent] = useState(null)

  const handleOpenSlideOver = useCallback((event) => {
    setSelectedEvent(event)
    setIsEventDetailsSlideOverOpen(true)
  }, [])

  const tbodyCellStyle = 'px-6 py-4 z-1 break-words z-1'

  const handleSearch = (e) => {
    const filter = e.target.value
    setPageable((prev) => ({ ...prev, filter }))
  }

  const handleFilterEndedEvents = (e) => {
    const status = e.target.checked
      ? 'FINISHED,IN_PROGRESS,CREATED'
      : 'IN_PROGRESS,CREATED'
    setShowEndedEvents(e.target.checked)
    setPageable((prev) => ({ ...prev, status }))
    // TODO: Handle filtering
  }

  return (
    <>
      <Formik initialValues={{ pageSize: pageable.size }}>
        <Form className="h-full overflow-hidden">
          <div className="flex flex-col h-full overflow-hidden pt-8 gap-6">
            <div className="flex justify-between items-center">
              <h1 className="text-body-900 text-lg font-bold">Події</h1>
              <label className="relative">
                <Search className="w-5 h-5 text-body-600 absolute top-2.5 left-3" />
                <input
                  type="text"
                  placeholder="Пошук"
                  className="input rounded-md pl-10"
                  onChange={handleSearch}
                />
              </label>
              <Button
                icon="Add"
                variant="secondary"
                onClick={() => setIsEventCreateSlideOverOpen(true)}
              >
                Додати подію
              </Button>
            </div>
            <div className="flex justify-between items-center">
              <label className="flex items-center gap-2.5">
                <input
                  type="checkbox"
                  id="showEndedEvents"
                  className="hidden opacity-0"
                  onChange={handleFilterEndedEvents}
                />
                <div
                  className={`flex justify-center items-center border border-primary-500 rounded-md h-4 w-4 ${
                    showEndedEvents ? 'bg-primary-500' : ''
                  }`}
                >
                  {showEndedEvents && (
                    <Checkmark className="w-4 h-4 text-white" />
                  )}
                </div>
                Показувати завершені
              </label>
              <div className="text-sm text-body-600">
                Відображено {events.length} із {pageDetails.totalItems}
              </div>
              <div className="flex items-center gap-2">
                <span className="text-sm text-body-900">
                  Результатів на cторінку
                </span>
                <Select
                  size="small"
                  options={pageSizeOptions}
                  value={String(pageable.size)}
                  name="pageSize"
                  onChange={handlePageSizeChange}
                />
              </div>
            </div>

            <div className="overflow-auto rounded-md relative">
              {loading && <LoadingOverlay />}

              <table className="min-w-[1024px] overflow-y-auto divide-y divide-gray-200 table-fixed w-full">
                <thead className="bg-body-50/70 backdrop-blur-sm sticky top-0 z-10">
                  <tr>
                    <th
                      onClick={() => handleSortChange('createdAt')}
                      className={tableHeaderCellStyle}
                      style={{ width: '15%' }}
                    >
                      <div className="flex gap-2.5 items-center">
                        Час виникнення
                        {pageable.sortBy === 'eventType' ? (
                          pageable.sortOrder === 'asc' ? (
                            <Icon
                              name="SortAsc"
                              className="w-6 h-6 text-primary-400"
                            />
                          ) : (
                            <Icon
                              name="SortDesc"
                              className="w-6 h-6 text-primary-400"
                            />
                          )
                        ) : (
                          <Icon
                            name="SortDesc"
                            className="w-6 h-6 text-primary-100"
                          />
                        )}
                      </div>
                    </th>
                    <th
                      onClick={() => handleSortChange('eventType')}
                      className={tableHeaderCellStyle}
                      style={{ width: '15%' }}
                    >
                      <div className="flex gap-2.5 items-center">
                        Тип події
                        {pageable.sortBy === 'eventType' ? (
                          pageable.sortOrder === 'asc' ? (
                            <Icon
                              name="SortAsc"
                              className="w-6 h-6 text-primary-400"
                            />
                          ) : (
                            <Icon
                              name="SortDesc"
                              className="w-6 h-6 text-primary-400"
                            />
                          )
                        ) : (
                          <Icon
                            name="SortDesc"
                            className="w-6 h-6 text-primary-100"
                          />
                        )}
                      </div>
                    </th>
                    <th className={tableHeaderCellStyle} style={{width: '40%'}}>
                      <div className="flex gap-2.5 items-center">Адреса</div>
                    </th>
                    <th
                      onClick={() => handleSortChange('status')}
                      className={tableHeaderCellStyle}
                      style={{ width: '15%' }}
                    >
                      <div className="flex gap-2.5 items-center">
                        Статус
                        {pageable.sortBy === 'status' ? (
                          pageable.sortOrder === 'asc' ? (
                            <Icon
                              name="SortAsc"
                              className="w-6 h-6 text-primary-400"
                            />
                          ) : (
                            <Icon
                              name="SortDesc"
                              className="w-6 h-6 text-primary-400"
                            />
                          )
                        ) : (
                          <Icon
                            name="SortDesc"
                            className="w-6 h-6 text-primary-100"
                          />
                        )}
                      </div>
                    </th>
                    <th className={tableHeaderCellStyle} style={{width: '15%'}}>
                      <div className="flex gap-2.5 items-center">Залучено</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {error ? (
                    <tr>
                      <td colSpan="5" className="text-center py-4">
                        {error}
                      </td>
                    </tr>
                  ) : (
                    events.map((event) => {
                      const { text: type } = EventTypeDetails[event.eventType]
                      const { text: statusText, color: statusColor } =
                        EventStatusDetails[event.status]
                      const { city, street, buildingNumber, apartmentNumber } =
                        event.address

                      return (
                        <tr key={event.id} onClick={() => handleOpenSlideOver(event)} className='hover:bg-body-50 hover:cursor-pointer'>
                          <td className={tbodyCellStyle}>
                            {new Date(event.createdAt).toLocaleTimeString(
                              'uk-UA',
                              {
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit'
                              }
                            )}{' '}
                            {new Date(event.createdAt).toLocaleDateString(
                              'uk-UA',
                              {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric'
                              }
                            )}
                          </td>
                          <td className={tbodyCellStyle}>
                            {type}
                          </td>
                          <td className={tbodyCellStyle}>
                            {city}
                            {street && `, ${street}`}
                            {buildingNumber && `, ${buildingNumber}`}
                            {apartmentNumber && `, кв. ${apartmentNumber}`}
                          </td>
                          <td className={tbodyCellStyle}>
                            <Status
                              placeholder={statusText}
                              value={statusText}
                              color={statusColor}
                            />
                          </td>
                          <td className={tbodyCellStyle}>
                            {event.volunteers.length}
                          </td>
                        </tr>
                      )
                    })
                  )}
                </tbody>
              </table>
            </div>
            <Pagination
              hasPrevious={pageDetails.hasPrevious}
              hasNext={pageDetails.hasNext}
              currentPage={pageDetails.page}
              totalPages={pageDetails.totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </Form>
      </Formik>
      <EventCreateSlideOver
        open={isEventCreateSlideOverOpen}
        setOpen={setIsEventCreateSlideOverOpen}
      />

      {selectedEvent && (
        <EventDetailsSlideOver
          open={isEventDetailsSlideOverOpen}
          setOpen={setIsEventDetailsSlideOverOpen}
          event={selectedEvent}
        />
      )}
    </>
  )
}

export default EventsPage
