import * as React from 'react'

const Svg = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 5.75H18.5V3.5H5.5V5.75ZM4 2H20V14C20 18 15.5 22 12 22C8.5 22 4 18 4 14V2ZM18.5 7.25V14C18.5 15.4567 17.6555 17.0914 16.2737 18.4179C14.8833 19.7527 13.236 20.5 12 20.5C10.764 20.5 9.11667 19.7527 7.7263 18.4179C6.34448 17.0914 5.5 15.4567 5.5 14V7.25H18.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2399 10.5066L12 9L10.7601 10.5066L8.81802 10.318L9.00663 12.2601L7.5 13.5L9.00663 14.7399L8.81802 16.682L10.7601 16.4934L12 18L13.2399 16.4934L15.182 16.682L14.9934 14.7399L16.5 13.5L14.9934 12.2601L15.182 10.318L13.2399 10.5066ZM14.0692 11.4308L12.8063 11.5534L12 10.5737L11.1937 11.5534L9.93079 11.4308L10.0534 12.6937L9.0737 13.5L10.0534 14.3063L9.93079 15.5692L11.1937 15.4466L12 16.4263L12.8063 15.4466L14.0692 15.5692L13.9466 14.3063L14.9263 13.5L13.9466 12.6937L14.0692 11.4308ZM5.5 5.75V3.5H18.5V5.75H5.5ZM4 2V14C4 18 8.5 22 12 22C15.5 22 20 18 20 14V2H4ZM18.5 7.25H5.5V14C5.5 15.4567 6.34448 17.0914 7.7263 18.4179C9.11667 19.7527 10.764 20.5 12 20.5C13.236 20.5 14.8833 19.7527 16.2737 18.4179C17.6555 17.0914 18.5 15.4567 18.5 14V7.25Z"
    />
  </svg>
)

export default Svg
