import * as React from 'react'
const SvgSortDesc = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
  >
    <path d="M2.217 15.548a.714.714 0 0 0-.015 1.01l2.747 2.832a.71.71 0 0 0 1.024 0h.001l2.748-2.832a.714.714 0 1 0-1.026-.994l-1.52 1.567V5.714a.714.714 0 1 0-1.429 0v11.417l-1.52-1.567a.714.714 0 0 0-1.01-.016M19.771 10.107a.714.714 0 0 0-.714-.715h-7.751a.714.714 0 1 0 0 1.429h7.751c.395 0 .714-.32.714-.714M17.543 14.5a.714.714 0 0 0-.715-.714h-5.522a.714.714 0 1 0 0 1.428h5.522c.395 0 .715-.32.715-.714M11.306 18.18a.714.714 0 1 0 0 1.43H14.6a.714.714 0 1 0 0-1.43zM21.286 5h-9.98a.714.714 0 1 0 0 1.429h9.98a.714.714 0 1 0 0-1.429" />
  </svg>
)
export default SvgSortDesc
