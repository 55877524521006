const Footer = () => {
  return (
    <footer className="bg-primary-50 py-5 mt-8 px-8">
      <div className="container mx-auto">
        <p>© {new Date().getFullYear()}, ДСНС</p>
      </div>
    </footer>
  )
}

export default Footer
