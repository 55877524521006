import { createAsyncThunk } from '@reduxjs/toolkit'

import * as facade from '../../api/auth.facade'
import { AUTH, LOGIN, REFRESH, REGISTER } from './constants'

export const register = createAsyncThunk(
  `${AUTH}/${REGISTER}`,
  async (data) => {
    return await facade.register(data)
  }
)

export const refresh = createAsyncThunk(`${AUTH}/${REFRESH}`, async (token) => {
  return await facade.refresh(token)
})

export const login = createAsyncThunk(`${AUTH}/${LOGIN}`, async (data) => {
  const response = await facade.login(data)

  return response
})
