import * as React from 'react'
const SvgRescuer = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
  >
    <path
      d="M9.353 5.286V5.18C9.353 4.528 9.88 4 10.53 4h2.938c.65 0 1.178.528 1.178 1.179v.107a8.83 8.83 0 0 1 6.177 8.42v.588c.65 0 1.176.528 1.176 1.178v2.938a1.18 1.18 0 0 1-1.177 1.178H3.177c-.65 0-1.177-.527-1.177-1.178v-2.938c0-.65.528-1.177 1.176-1.178v-.588a8.83 8.83 0 0 1 6.177-8.42M3.177 15.471v2.939l17.646.002v-2.94l-6.11-.001a3.234 3.234 0 0 1-5.425 0zm12.058-1.765q0 .3-.053.588h4.465v-.588a7.65 7.65 0 0 0-5-7.177v5.316a3.23 3.23 0 0 1 .588 1.86m-5.882-1.861V6.529a7.65 7.65 0 0 0-5 7.177v.588h4.465a3.25 3.25 0 0 1 .535-2.45m4.118-6.666-2.94-.003-.002 5.647A3.2 3.2 0 0 1 12 10.471a3.2 3.2 0 0 1 1.47.352zm-3.53 8.527a2.059 2.059 0 1 0 4.117 0 2.059 2.059 0 0 0-4.117 0"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgRescuer
