import { NavLink } from 'react-router-dom'
import Icon from '../components/Icon'
import avatar from '../assets/avatar.png'
import {useDispatch, useSelector} from 'react-redux'

const NavigationBar = () => {
  const dispatch = useDispatch()
  const { username } = useSelector((state) => state.auth)
  const linkStyle =
    'flex items-center text-primary-400 border-b-4 border-transparent ' +
    'hover:text-gray-800 hover:no-underline ' +
    'pb-6 xl:py-6 px-2 gap-2 '
  const linkActive = 'border-primary-400'
  const linkIconBoxStyle = 'bg-primary-50 p-0.5 rounded'
  const linkIconStyle = 'w-5 h-5'

  const handelLogout = async () => {
    dispatch({ type: 'auth/logout' })
  }

  return (
    <nav className="bg-white shadow-md">
      <div className="grid grid-cols-2 xl:grid-cols-12 max-xl:grid-rows-2 gap-5 px-8 items-center w-full">
        <div className="pt-6 xl:py-6 col-span-1 xl:col-span-3 flex items-center">
          <NavLink
            to="/"
            className="text-lg xl:text-2xl font-bold text-primary-400"
          >
            Я—ДОБРОВОЛЕЦЬ
          </NavLink>
        </div>
        <div className="max-xl:row-start-2 max-xl:row-end-3 col-span-2 xl:col-span-6 w-full justify-between flex gap-6 xl:gap-12">
          <NavLink
            to="/map"
            className={({ isActive }) =>
              isActive ? `${linkStyle} ${linkActive}` : linkStyle
            }
          >
            <div className={linkIconBoxStyle}>
              <Icon name="Map" className={linkIconStyle} />
            </div>
            <span className="hidden sm:inline">Мапа</span>
          </NavLink>
          <NavLink
            to="/events"
            className={({ isActive }) =>
              isActive ? `${linkStyle} ${linkActive}` : linkStyle
            }
          >
            <div className={linkIconBoxStyle}>
              <Icon name="Event" className={linkIconStyle} />
            </div>
            <span className="hidden sm:inline">Події</span>
          </NavLink>
          <NavLink
            to="/volunteers"
            className={({ isActive }) =>
              isActive ? `${linkStyle} ${linkActive}` : linkStyle
            }
          >
            <div className={linkIconBoxStyle}>
              <Icon name="Helmet" className={linkIconStyle} />
            </div>
            <span className="hidden sm:inline">Добровольці</span>
          </NavLink>
          <NavLink
            to="/analytics"
            className={({ isActive }) =>
              isActive ? `${linkStyle} ${linkActive}` : linkStyle
            }
          >
            <div className={linkIconBoxStyle}>
              <Icon name="Analytics" className={linkIconStyle} />
            </div>
            <span className="hidden sm:inline">Аналітика</span>
          </NavLink>
        </div>
        <div className="pt-6 xl:py-6 col-span-1 xl:col-span-3 flex items-center justify-end gap-4">
          <div className="flex items-center gap-2">
            <img src={avatar} alt="Avatar" className="w-10 h-10 rounded-full" />
            <span className="text-primary-400 text-base leading-6">
              {username}
            </span>
          </div>
          <button onClick={handelLogout}>
            <Icon name="Logout" className="w-5 h-5 text-primary-400" />
          </button>
        </div>
      </div>
    </nav>
  )
}

export default NavigationBar
