import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  isValid,
  startOfMonth,
  startOfWeek,
  startOfYear
} from 'date-fns'
import { uk } from 'date-fns/locale'
import React, { useEffect, useRef } from 'react'

const DateNavigation = ({ startDate, endDate, view, onDateChange }) => {
  const calculateDateRange = useRef(() => {
    let newStartDate
    let newEndDate

    switch (view) {
      case 'day':
        newStartDate = startDate
        newEndDate = startDate
        break
      case 'week':
        newStartDate = startOfWeek(startDate, { weekStartsOn: 1, locale: uk })
        newEndDate = endOfWeek(newStartDate, { weekStartsOn: 1, locale: uk })
        break
      case 'month':
        newStartDate = startOfMonth(startDate)
        newEndDate = endOfMonth(newStartDate)
        break
      case 'year':
        newStartDate = startOfYear(startDate)
        newEndDate = endOfYear(newStartDate)
        break
      default:
        return
    }

    onDateChange(newStartDate, newEndDate)
  })

  useEffect(() => {
    calculateDateRange.current()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderDays = () => {
    const dates = []
    for (let i = -3; i <= 3; i++) {
      dates.push(addDays(startDate, i))
    }

    return dates.map((date) => (
      <div
        key={date}
        onClick={() => onDateChange(date, date)}
        className={`inline-block p-2 cursor-pointer mx-1 ${
          isValid(date) &&
          format(date, 'yyyy-MM-dd') === format(startDate, 'yyyy-MM-dd')
            ? 'bg-primary-500 text-white rounded'
            : 'bg-white text-black rounded'
        }`}
      >
        {isValid(date) ? format(date, 'dd.MM', { locale: uk }) : 'Invalid Date'}
      </div>
    ))
  }

  const renderWeeks = () => {
    const startOfWeekDate = startOfWeek(startDate, {
      weekStartsOn: 1,
      locale: uk
    })
    const weeks = []
    for (let i = -3; i <= 3; i++) {
      const start = addWeeks(startOfWeekDate, i)
      const end = endOfWeek(start, { weekStartsOn: 1, locale: uk })
      weeks.push({ start, end })
    }

    return weeks.map(({ start, end }) => (
      <div
        key={start}
        onClick={() => onDateChange(start, end)}
        className={`inline-block p-2 cursor-pointer mx-1 ${
          isValid(start) && isValid(end) && start <= startDate && end >= endDate
            ? 'bg-primary-500 text-white rounded'
            : 'bg-white text-black rounded'
        }`}
      >
        {isValid(start) && isValid(end)
          ? `${format(start, 'dd.MM', { locale: uk })} - ${format(end, 'dd.MM', { locale: uk })}`
          : 'Invalid Date'}
      </div>
    ))
  }

  const renderMonths = () => {
    const months = []
    for (let i = -5; i <= 6; i++) {
      const monthStart = startOfMonth(
        addMonths(new Date(startDate.getFullYear(), startDate.getMonth(), 1), i)
      )
      const monthEnd = endOfMonth(monthStart)
      months.push({ start: monthStart, end: monthEnd })
    }

    return months.map(({ start, end }) => (
      <div
        key={start}
        onClick={() => onDateChange(start, end)}
        className={`inline-block p-2 cursor-pointer mx-1 ${
          isValid(start) && isValid(end) && start <= startDate && end >= endDate
            ? 'bg-primary-500 text-white rounded'
            : 'bg-white text-black rounded'
        }`}
      >
        {isValid(start)
          ? format(start, 'MMMM', { locale: uk })
          : 'Invalid Date'}
      </div>
    ))
  }

  const renderYears = () => {
    const currentYear = new Date().getFullYear()
    const years = []
    for (let i = currentYear - 5; i <= currentYear + 1; i++) {
      const yearStart = startOfYear(new Date(i, 0, 1))
      const yearEnd = endOfYear(yearStart)
      years.push({ start: yearStart, end: yearEnd })
    }

    return years.map(({ start, end }) => (
      <div
        key={start}
        onClick={() => onDateChange(start, end)}
        className={`inline-block p-2 cursor-pointer mx-1 ${
          isValid(start) && isValid(end) && start <= startDate && end >= endDate
            ? 'bg-primary-500 text-white rounded'
            : 'bg-white text-black rounded'
        }`}
      >
        {isValid(start) ? format(start, 'yyyy') : 'Invalid Date'}
      </div>
    ))
  }

  const handlePrev = () => {
    let newStartDate
    let newEndDate
    switch (view) {
      case 'day':
        newStartDate = addDays(startDate, -1)
        newEndDate = newStartDate
        break
      case 'week':
        newStartDate = addWeeks(startDate, -1)
        newEndDate = endOfWeek(newStartDate, { weekStartsOn: 1, locale: uk })
        break
      case 'month':
        newStartDate = addMonths(startDate, -1)
        newEndDate = endOfMonth(newStartDate)
        break
      case 'year':
        newStartDate = addYears(startDate, -1)
        newEndDate = endOfYear(newStartDate)
        break
      default:
        return
    }
    onDateChange(newStartDate, newEndDate)
  }

  const handleNext = () => {
    let newStartDate
    let newEndDate
    switch (view) {
      case 'day':
        newStartDate = addDays(startDate, 1)
        newEndDate = newStartDate
        break
      case 'week':
        newStartDate = addWeeks(startDate, 1)
        newEndDate = endOfWeek(newStartDate, { weekStartsOn: 1, locale: uk })
        break
      case 'month':
        newStartDate = addMonths(startDate, 1)
        newEndDate = endOfMonth(newStartDate)
        break
      case 'year':
        newStartDate = addYears(startDate, 1)
        newEndDate = endOfYear(newStartDate)
        break
      default:
        return
    }
    onDateChange(newStartDate, newEndDate)
  }

  return (
    <div className="mb-9 w-full flex items-center justify-between">
      <button
        onClick={handlePrev}
        className="px-2 py-1 m-1 bg-gray-300 text-black rounded"
      >
        &lt;
      </button>
      {view === 'day' && renderDays()}
      {view === 'week' && renderWeeks()}
      {view === 'month' && renderMonths()}
      {view === 'year' && renderYears()}
      <button
        onClick={handleNext}
        className="px-2 py-1 m-1 bg-gray-300 text-black rounded"
      >
        &gt;
      </button>
    </div>
  )
}

export default DateNavigation
